import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//Sites and Components IMPORT
import Navbar from "./components/nav/nav";
import Search from "./pages/search/search";
import Home from "./pages/home/home";
import Contact from "./pages/contact/contact";
import Footer from "./components/footer/footer";
import NotFound from "./pages/notfound/notfound";
import Datenschutz from "./pages/datenschutz/datenschutz";
import Impressum from "./pages/impressum/impressum";
import Gallery from "./pages/gallery/gallery";

//CSS IMPORT
import "./App.css";
import Events from "./pages/events/events";
import Coop from "./pages/coop/coop";
import Ref from "./pages/ref/ref";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route  path="*" element={<NotFound />} />
        <Route path="/search" element={<Search />} />
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/datenschutz" element={<Datenschutz />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/gallery" element={<Gallery/>} />
        <Route path="/events" element={<Events /> } />  
        <Route path="/kooperationen" element={<Coop /> } />
        <Route path="/referenzen" element={<Ref /> } /> 
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

import React from "react";
import "./infobox.css";
import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";

function Infobox(props) {
  const {
    vorname,
    name,
    titel,
    adresse,
    geburtsdatum,
    verlegungsdatum,
    schicksal,
    schicksal_en,
    bild,
    biographie,
    biographie_en,
    quelle,
  } = props.person;
  const { t, i18n } = useTranslation();
  const handlePrint = () => {
    window.print();
  };
  return (
    <div className="popup-container">
      <div className="popup">
        <div className="buttons">
          <button className="print-button" onClick={handlePrint}>
            🖨
          </button>
          <button className="close-button" onClick={props.onClose}>
            <span className="close-icon">&times;</span>
          </button>
        </div>
        <div className="header">
          <h2>
            🟨 {titel} {vorname} {name}
          </h2>
          { (bild != null) &&
          <img
            className="image"
            src={"https://stolpersteine-tuebingen.de" +  bild}
            alt={props.nachname}
          ></img>
          }
        </div>
        <div className="details">
          <ul>
            <li>
              <span className="label">{t("search.placeholder.street")}: </span>{" "}
              {adresse}
            </li>
            {verlegungsdatum && (
              <li>
                <span className="label">
                  {t("search.placeholder.verlegedatum")}:{" "}
                </span>{" "}
                {verlegungsdatum}
              </li>
            )}
            <li>
              <span className="label">
                {t("search.placeholder.birthdate")}:{" "}
              </span>{" "}
              {geburtsdatum}
            </li>
            <li>
              <span className="label">
                {t("search.placeholder.schicksal")}:{" "}
              </span>{" "}
              {schicksal_en !== "" && i18n.language.includes("en") ? (
                <>{schicksal_en}</>
              ) : (
                <>{schicksal}</>
              )}
            </li>
          </ul>
          <p className="biography">
            {biographie_en !== "" && i18n.language.includes("en") ? (
              <Markdown>{biographie_en}</Markdown>
            ) : (
              <Markdown>{biographie}</Markdown>
            )}
          </p>
          <p className="sources">
            <span className="label">{t("search.placeholder.source")}:</span>
            {quelle}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Infobox;

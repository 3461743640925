import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./events.css";
import FAQItem from "../../components/faq/faqitem";
import Markdown from "react-markdown";
function Events() {
  const { t, i18n } = useTranslation();
  const [termine, setTermine] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    fetch("https://stolpersteine-tuebingen.de/servertermine")
      .then((response) => response.json())
      .then((data) => {
        
        data.sort((a, b) => new Date(a.datum) - new Date(b.datum));
        setTermine(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching termine:", error);
        setError(true);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    document.title = t("navbar.events") + " - Tübinger Stolpersteine";
  }, [t]);
  
  if (error)
    return (
      <div className="center">
        <div className="error">Error: Failed to fetch the data</div>
      </div>
    );
  if (loading)
    return (
      <div className="center">
        <div className="loader"></div>
      </div>
    );

  return (
    <div className="loaded">
      <h1>{t("navbar.events")}</h1>
      <ul>
        {termine && termine.length > 0 ? (
          termine.map((termin, index) => (
            <div key={index}>
              <FAQItem
                question={
                  <>
                    {formatDateTime(termin.datum, termin.zeit, i18n.language)}
                    <br />
                    {termin.name}
                  </>
                }
                answer={<Markdown>{termin.body}</Markdown>}
              />
            </div>
          ))
        ) : (
          <p>{t("termin.error")}</p>
        )}
      </ul>
    </div>
  );
}

export default Events;

function formatDateTime(dateString, timeString, language) {
  // Erzeuge ein Date-Objekt aus den übergebenen Strings
  const dateTime = new Date(`${dateString}T${timeString}`);

  // Erstelle Optionen für die Datum- und Zeitformatierung
  const dateFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const timeFormatOptions = { hour: "2-digit", minute: "2-digit" };

  // Wähle die Locale entsprechend der Spracheinstellung
  const locale = language.includes("en") ? "en-US" : "de-DE";

  // Formatiere Datum und Zeit separat mit der entsprechenden Locale
  const formattedDate = dateTime.toLocaleDateString(locale, dateFormatOptions);
  const formattedTime = dateTime.toLocaleTimeString(locale, timeFormatOptions);

  // Gib das formatierte Datum und die formatierte Zeit zurück
  return `${formattedDate} ${formattedTime}`;
}

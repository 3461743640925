import React, {useEffect} from "react";
import "./notfound.css";
import { useTranslation } from "react-i18next";

function NotFound() {
  const [t] = useTranslation();
   useEffect(() => {
    document.title = t("notfound.headline") + ' - Tübinger Stolpersteine'; 
  }, [t]);
  return (
    <div className="loaded">
      <h1>{t("notfound.headline")}</h1>
      <h3>{t("notfound.text")}</h3>
    </div>
  );
}

export default NotFound;

import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./footer.css";

function Footer() {
  const { t } = useTranslation();

  return (
    <footer>
      <Link to="/datenschutz">{t("footer.privacy")}</Link>
      <Link to="/impressum">{t("footer.impressum")}</Link>
    </footer>
  );
}

export default Footer;

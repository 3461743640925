import React, { useEffect, useState } from "react";
import "./datenschutz.css";
import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";

function Datenschutz() {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    document.title = t("footer.privacy") + " - Tübinger Stolpersteine";

    // API-Anfrage stellen, um den Text mit der ID 4 abzurufen
    fetch("https://stolpersteine-tuebingen.de/servertext/4/")
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching text:", error);
        setLoading(false);
        setError(true);
      });
  }, [t]);

  if (error)
    return (
      <div className="center">
        <div className="error">Error: Failed to fetch the data</div>
      </div>
    );
  if (loading)
    return (
      <div className="center">
        <div className="loader"></div>
      </div>
    );


  return (
    <div className="datenschutz-div loaded">
      <h1>{t("footer.privacy")}</h1>
      <Markdown>
        {data.text_en !== "" && i18n.language.includes("en")
          ? data.text_en
          : data.text}
      </Markdown>
    </div>
  );
}

export default Datenschutz;

import { useState, useEffect } from 'react';
import './slideshow.css';

function Slideshow({ images }) {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((currentImage + 1) % images.length);
    }, 10000);
    return () => clearInterval(interval);
  }, [currentImage, images.length]);

  return (
    <div className="slideshow dunkel">
      {images.map((image, index) => (
        <img
          key={image}
          src={image}
          
          alt="Startbild"
          className={index === currentImage ? 'active' : ''}
        />
      ))}
    </div>
  );
}
export default Slideshow
import React, { useState, useEffect } from "react";
import "./searchoutput.css";
import { useTranslation } from "react-i18next";

const Searchoutput = (props) => {
  const [, i18n] = useTranslation();
  const { testdata } = props;
  const [data, setData] = useState(testdata);
  const [sortOrder, setSortOrder] = useState("desc");
  const [arrowDirection, setArrowDirection] = useState({
    name: "down",
    adresse: "",
    geburtsdatum: "",
    area: "",
  });

  useEffect(() => {
    if (testdata) {
      setData([...testdata].sort((a, b) => a.name.localeCompare(b.name)));
    }
  }, [testdata]);


  const handleSort = (key) => {
    setData((prevData) => {
      const sortedData = [...prevData].sort((a, b) => {
        if (key === "name") {
          const lastNameCompare = a["name"].localeCompare(b["name"]);
          if (lastNameCompare === 0) {
            return a["vorname"].localeCompare(b["vorname"]);
          } else {
            return lastNameCompare;
          }
        } else if (key === "adresse") {
          return a["adresse"].localeCompare(b["adresse"]);
        } else if (key === "geburtsdatum") {
          return a["geburtsdatum"].localeCompare(b["geburtsdatum"]);
        } else if (key === "schicksal") {
          return a["schicksal"].localeCompare(b["schicksal"]);
        } else {
          return 0;
        }
      });
      return sortOrder === "asc" ? sortedData : sortedData.reverse();
    });
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    setArrowDirection((prevArrow) => ({
      name: key === "name" ? (prevArrow.name === "up" ? "down" : "up") : "",
      vorname: key === "vorname" ? (prevArrow.vorname === "up" ? "down" : "up") : "",
      adresse:
        key === "adresse" ? (prevArrow.adresse === "up" ? "down" : "up") : "",
      geburtsdatum:
        key === "geburtsdatum"
          ? prevArrow.geburtsdatum === "up"
            ? "down"
            : "up"
          : "",
      area: key === "schicksal" ? (prevArrow.area === "up" ? "down" : "up") : "",
    }));
  };
  
  
  if (!data || data.length === 0) {
    return <div className="no-results">{props.t('search.no-data')}</div>;
  }

  
  return (
    <table className="grid-container">
      <thead>
        <tr>
          <th
            onClick={() => handleSort("name")}
            className="search-result-label"
          >
            {props.t('search.placeholder.name')}{" "}
            {arrowDirection.name === "up"
              ? "⬆"
              : arrowDirection.name === "down"
              ? "⬇"
              : ""}
          </th>
          <th
            onClick={() => handleSort("adresse")}
            className="search-result-label"
          >
            {props.t('search.placeholder.street')}{" "}
            {arrowDirection.adresse === "up"
              ? "⬆"
              : arrowDirection.adresse === "down"
              ? "⬇"
              : ""}
          </th>
          <th
            onClick={() => handleSort("geburtsdatum")}
            className="search-result-label"
          >
            {props.t('search.placeholder.birthdate')}{" "}
            {arrowDirection.geburtsdatum === "up"
              ? "⬆"
              : arrowDirection.geburtsdatum === "down"
              ? "⬇"
              : ""}
          </th>
          <th
            onClick={() => handleSort("schicksal")}
            className="search-result-label"
          >
            {props.t('search.placeholder.schicksal')}{" "}
            {arrowDirection.area === "up"
              ? "⬆"
              : arrowDirection.area === "down"
              ? "⬇"
              : ""}
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr
            className="search-result-item"
            key={item.id}
            onClick={() => {
              props.onChange(item);
            }}
          >
            <td className="search-result-value">
              {item.name}, {item.vorname}
            </td>
            <td className="search-result-value">{item.adresse}</td>
            <td className="search-result-value">{item.geburtsdatum}</td>
            <td className="search-result-value">{(item.schicksal_en !== "" && i18n.language.includes('en')) ? (
              <>{item.schicksal_en}</>
            ) : (
              <>{item.schicksal}</>
            )}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
export default Searchoutput;

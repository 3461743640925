import "./mapsearch.css";
import "leaflet/dist/leaflet.css";
import MarkerClusterGroup from "react-leaflet-cluster";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { icon } from "leaflet";
import stolperstein from "./stolpersteine.png";


export default function Mapsearch(props) {
  // Definieren Sie das Icon-Objekt

  const myIcon = icon({
    iconUrl: stolperstein,
    iconSize: [15, 15],
  });
  return (
    <MapContainer
      center={[48.52266, 9.05222]}
      scrollWheelZoom={false}
      zoom={14}
      minZoom={12}
    >
      <TileLayer
        url="https://api.maptiler.com/maps/basic-v2/{z}/{x}/{y}.png?key=YseCqF0fZKGueUSoaRRn"
        attribution='<a style="font-size: 10px;"href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a style="font-size: 10px;" href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
      />
      <MarkerClusterGroup>
        {props.testdata.map((marker) => {
          // Überprüfen, ob geocode existiert und gültig ist
          if (!marker.geocode || !marker.geocode.includes(",")) {
            return null;
          }
          const [lat, lng] = marker.geocode.split(",");
          return (
            <Marker key={marker.id} position={[lat, lng]} icon={myIcon}>
              <Popup>
                <div
                  className="click"
                  onClick={() => {
                    props.onChange(marker);
                  }}
                >
                  <b>{marker.adresse} :</b>
                  <br></br>🟨 {marker.name}, {marker.vorname}
                </div>
              </Popup>
            </Marker>
          );
        })}
      </MarkerClusterGroup>
    </MapContainer>
  );
}

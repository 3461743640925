import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Slideshow from "../../components/slideshow/slideshow";
import { Link } from "react-router-dom";
import Markdown from "react-markdown";
import "react-slideshow-image/dist/styles.css";
import "./home.css";

function Home() {
  const [infoStolpersteine, setInfoStolpersteine] = useState(false);
  const [popup, setPopup] = useState(true);
  const [markdown, setMarkdown] = useState("");
  const { t, i18n } = useTranslation();
  const [images, setImages] = useState([]);
  const [text, setText] = useState("");
  const [loadingText, setLoadingText] = useState(true);
  const [errorText, setErrorText] = useState(false);

  const [loadingPopUp, setLoadingPopUp] = useState(true);
  const [errorPopUp, setErrorPopUp] = useState(false);
  useEffect(() => {
    document.title = "Tübinger Stolpersteine";
    // Fetch images from the server API
    async function fetchImages() {
      try {
        const response = await fetch(
          "https://stolpersteine-tuebingen.de/serverstartbilder"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch images");
        }
        const data = await response.json();
        setImages(
          data.map(
            (image) => "https://stolpersteine-tuebingen.de" + image.bild
          )
        ); // Extract locations
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    }
    async function fetchPopup() {
      try {
        const response = await fetch(
          "https://stolpersteine-tuebingen.de/servertext/3/"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch popup");
        }
        const data = await response.json();
        setMarkdown(
          data.text_en !== "" && i18n.language.includes("en")
            ? data.text_en
            : data.text
        );
        setLoadingPopUp(false);
      } catch (error) {
        console.error("Error fetching popup:", error);
        setErrorPopUp(true);
        setLoadingPopUp(false);
      }
    }
    async function fetchText() {
      try {
        const response = await fetch(
          "https://stolpersteine-tuebingen.de/servertext/2/"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch text");
        }
        const data = await response.json();
        setText(
          data.text_en !== "" && i18n.language.includes("en")
            ? data.text_en
            : data.text
        );
        setLoadingText(false);
      } catch (error) {
        console.error("Error fetching Text:", error);
        setLoadingText(false);
        setErrorText(true);
      }
    }

    const popupClosedDate = localStorage.getItem("popupClosedDate");
    const currentDate = getCurrentDate();

    if (popupClosedDate === currentDate) {
      setPopup(false);
    }
    fetchImages();
    fetchPopup();
    fetchText();
  }, [t, i18n.language]);

  return (
    <div>
      {images.length > 0 ? (
        <>
          <div className="slide-container">
            {popup && markdown !== "" && (
              <div className="popup-container">
                <div className="popup" id="popup-what">
                  <button
                    className="close-button"
                    onClick={() => {
                      setPopup(false);
                      localStorage.setItem("popupClosedDate", getCurrentDate());
                    }}
                  >
                    <span className="close-icon">&times;</span>
                  </button>
                  {!loadingPopUp && !errorPopUp && (
                    <Markdown>{markdown}</Markdown>
                  )}
                  {loadingPopUp && (
                    <div className="center">
                      <div className="loader"></div>
                    </div>
                  )}
                  {errorPopUp && (
                    <div className="center">
                      <div className="error">Error: Failed to fetch data</div>
                    </div>
                  )}
                </div>
              </div>
            )}

            <Slideshow images={images} />

            <div className="line">
              <h1 className="headline">{t("home.header.headline")}</h1>
              <h2 className="subline">{t("home.header.subline")}</h2>
            </div>
          </div>
        </>
      ) : (
        <div className="main">
          <p className="loader"></p>
        </div>
      )}
      <h3 className="scroll-unten">
        <div
          className="scroll-link"
          onClick={() => {
            document.getElementById("main").scrollIntoView();
          }}
        >
          ↓ {t("home.header.scroll")}
        </div>
      </h3>
      <div className="main" id="main">
        <div>
          <h3>{t("home.main.question.1")}</h3>
          <p>{t("home.main.answer.1")}</p>
          <Link
            className="link"
            to="#"
            onClick={() => {
              setInfoStolpersteine(true);
            }}
          >
            {t("home.main.button.1")}
          </Link>
        </div>
        <div>
          <h3>{t("home.main.question.2")}</h3>
          <p>{t("home.main.answer.2")}</p>
          <Link
            onClick={() => {
              document.getElementsByClassName("main").scrollIntoView();
            }}
            className="link"
            to="/search"
          >
            {t("home.main.button.2")}
          </Link>
        </div>
        <div>
          <h3>{t("home.main.question.3")}</h3>
          <p>{t("home.main.answer.3")}</p>
          <a className="link" href={"mailto:" + t("contact.mail")}>
            {t("home.main.button.3")}
          </a>
        </div>
      </div>
      {infoStolpersteine && (
        <div className="popup-container">
          <div className="popup" id="popup-what">
            <button
              className="close-button"
              onClick={() => {
                setInfoStolpersteine(false);
              }}
            >
              <span className="close-icon">&times;</span>
            </button>
            <h3>{t("home.main.question.1")}</h3>
            {!loadingText && !errorText && <Markdown>{text}</Markdown>}
            {loadingText && (
              <div className="center">
                <div className="loader"></div>
              </div>
            )}
            {errorText && (
              <div className="center">
                <div className="error">Error: Failed to fetch data</div>
              </div>
            )}
            <p>
              <b>{t("search.placeholder.source")}: </b>
              {t("initative")}
            </p>
          </div>
        </div>
      )}
      <div className="videos">
        <iframe
          className="yt"
          src="https://www.youtube-nocookie.com/embed/LbN6bAjmTaI"
          title="Stolperstein Video"
          allowFullScreen
        ></iframe>
        <iframe
          className="yt"
          src="https://www.youtube-nocookie.com/embed/J9h8I2Ki4lc"
          title="Stolperstein Video"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}

export default Home;

const getCurrentDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0]; // Gibt das Datum im Format "YYYY-MM-DD" zurück
};

import React, { useEffect, useState } from "react";
import PhotoGallery from "./PhotoGallery";
import { useTranslation } from "react-i18next";

function Gallery() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [photos, setPhotos] = useState([]);
  useEffect(() => {
    document.title = t("navbar.gallery") + " - Tübinger Stolpersteine";
  }, [t]);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          "https://stolpersteine-tuebingen.de/servergalerie"
        );
        const data = await response.json();
        setPhotos(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(true);
        console.error("Error:", error);
      }
    }
    fetchData();
  }, []);
  if (error)
    return (
      <div className="center">
        <div className="error">Error: Failed to fetch the data</div>
      </div>
    );
  if (loading)
    return (
      <div className="center">
        <div className="loader"></div>
      </div>
    );

  return (
    <div className="loaded">
      <h1>{t("navbar.gallery")}</h1> {/* Display a translated gallery title */}
      <PhotoGallery photos={photos} />
      {/* Display the photo gallery component */}
      <p>
        <b> {t("search.placeholder.source") + ": "}</b>
        {/* Display translated source information label */}
        {t("initative")} {/* Display translated source information text */}
      </p>
    </div>
  );
}

export default Gallery;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Infobox from "./infobox";
import Mapsearch from "./mapsearch";
import "./search.css";
import Searchoutput from "./searchoutput";

function Search() {
  const [testData, setData] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    document.title = t("navbar.search") + " - Tübinger Stolpersteine";
  }, [t]);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          "https://stolpersteine-tuebingen.de/server"
        );
        const data = await response.json();
        setLoading(false);
        setData(data);
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
        setError(true);
      }
    }
    fetchData();
  }, []);

  const [name, setName] = useState("");
  const [street, setStreet] = useState("");
  const [fate, setFate] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [advanced, setAdvanced] = useState(false);
  const [infoboxValue, setInfoboxValue] = useState(null);
  const handleName = (event) => {
    setName(event.target.value);
  };
  const handleStreet = (event) => {
    setStreet(event.target.value);
  };
  const handleFate = (event) => {
    setFate(event.target.value);
  };
  const handleBirthday = (event) => {
    setBirthdate(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const handleAdvanced = (event) => {
    if (advanced) {
      setAdvanced(false);
      setStreet("");
      setFate("");
      setBirthdate("");
    } else {
      setAdvanced(true);
    }
  };
  const closeInfoBox = () => {
    setInfoboxValue(null);
  };
  const filterPersons = (testData, name, street, fate, birthdate) => {
    console.log("Filtering data...");
    return testData.filter(
      (item) =>
        (item.vorname.toLowerCase().includes(name.toLowerCase()) ||
          item.name.toLowerCase().includes(name.toLowerCase()) ||
          name.toLowerCase().includes(item.vorname.toLowerCase()) ||
          name.toLowerCase().includes(item.name.toLowerCase())) &&
        item.adresse.toLowerCase().includes(street.toLowerCase()) &&
        (item.schicksal.toLowerCase().includes(fate.toLowerCase()) ||
          item.schicksal_en.toLowerCase().includes(fate.toLowerCase())) &&
        item.geburtsdatum.toLowerCase().includes(birthdate.toLowerCase())
    );
  };
  if (error)
    return (
      <div className="center">
        <div className="error">Error: Failed to fetch the data</div>
      </div>
    );
  if (loading)
    return (
      <div className="center">
        <div className="loader"></div>
      </div>
    );

  return (
    <div className="search loaded">
      <form onSubmit={handleSubmit}>
        <h1>{t("search.headline")}</h1>
        <div className="value-div">
          <label>
            <input
              className="value"
              value={name}
              onChange={handleName}
              placeholder={t("search.placeholder.name")}
            ></input>
            <div onClick={handleAdvanced} className="advancedSearch">
              {t("search.advancedSearch")}
            </div>
            {advanced && (
              <>
                <input
                  className="value"
                  value={street}
                  onChange={handleStreet}
                  placeholder={t("search.placeholder.street")}
                ></input>
                <input
                  className="value"
                  value={birthdate}
                  onChange={handleBirthday}
                  placeholder={t("search.placeholder.birthdate")}
                ></input>
                <input
                  className="value"
                  value={fate}
                  onChange={handleFate}
                  placeholder={t("search.placeholder.schicksal")}
                ></input>
              </>
            )}
          </label>
        </div>
      </form>
      <div className="gefunden">
        {filterPersons(testData, name, street, fate, birthdate).length}{" "}
        {t("search.of")} {testData.length} {t("search.found")}
      </div>
      <Mapsearch
        testdata={filterPersons(testData, name, street, fate, birthdate)}
        onChange={setInfoboxValue}
      />

      <Searchoutput
        className="searchoutput"
        testdata={filterPersons(testData, name, street, fate, birthdate)}
        onChange={setInfoboxValue}
        t={t}
      />
      <div>
        {infoboxValue && (
          <Infobox person={infoboxValue} onClose={closeInfoBox} />
        )}
      </div>
    </div>
  );
}

export default Search;

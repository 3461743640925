import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import FAQItem from "./faqitem";
import Markdown from "react-markdown";
function FAQ() {
  const { t, i18n } = useTranslation();
  const [faqs, setFAQ] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetch("https://stolpersteine-tuebingen.de/serverfaq")
      .then((response) => response.json())
      .then((data) => {
        setFAQ(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching termine:", error);
        setError(true);
        setLoading(false);
      });
  }, []);
  if (error)
    return (
      <div className="center">
        <div className="error">Error: Failed to fetch the data</div>
      </div>
    );
  if (loading)
    return (
      <div className="center">
        <div className="loader"></div>
      </div>
    );
  return (
    <div className="App">
      <ul>
        {faqs && faqs.length > 0 ? (
          faqs.map((faq, index) => (
            <div key={index}>
              <FAQItem
                question={
                  i18n.language.includes("en") && faq.frage_en !== ""
                    ? faq.frage_en
                    : faq.frage
                }
                answer={
                  <Markdown>
                    {i18n.language.includes("en") && faq.antwort_en !== ""
                      ? faq.antwort_en
                      : faq.antwort}
                  </Markdown>
                }
              />
            </div>
          ))
        ) : (
          <p>{t("faq.error")}</p>
        )}
      </ul>
    </div>
  );
}

export default FAQ;

import React, { useState } from "react";
import "./faqitem.css";

function FAQItem({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item">
      <div
        className="faq-question"
        onClick={() => {
          toggleOpen(isOpen);
        }}
      >
        <div>{question}</div>
        <div className="ausklappen">{isOpen ? "-" : "+"}</div>
      </div>

      <div className={`faq-answer ${isOpen ? "open" : ""}`}>
        {typeof answer === "string" ? (
          answer.split("\n").map((item, index) => (
            <React.Fragment key={index}>
              {item}
              <br />
            </React.Fragment>
          ))
        ) : (
          answer
        )}
      </div>
    </div>
  );
}

export default FAQItem;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./nav.css";
import { useTranslation } from "react-i18next";
import icon from "../../static/icons/stolpersteine.png";

function Nav() {
  const { t , i18n} = useTranslation();
  const [isResponsive, setIsResponsive] = useState(false);
  const handleChangeLanguage = (event) => {
    const language = event.target.value;
    i18n.changeLanguage(language);
    toggleNav();
  };
  const toggleNav = () => setIsResponsive(!isResponsive);

  return (
    <div className="navbar">
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      ></link>
      <nav className={`topnav ${isResponsive ? "responsive" : ""}`}>
        <Link to="/" className="nav-link active ">
          <img src={icon} alt="icon" className="bild" />
        </Link>
        <Link to="#" id="selection" className="nav-link">
          <select className="select" value={i18n.language} onChange={handleChangeLanguage}>
            <option value="en">🇬🇧 English</option>
            <option value="de">🇩🇪 Deutsch</option>
          </select>
        </Link>
        <Link to="/events" className="nav-link" onClick={toggleNav}>
          {t("navbar.events")}
        </Link>
        <Link to="/search" className="nav-link" onClick={toggleNav}>
          {t("navbar.search")}
        </Link>
        <Link to="/gallery" className="nav-link" onClick={toggleNav}>
          {t("navbar.gallery")}
        </Link>
        <Link to="/contact" className="nav-link" onClick={toggleNav}>
          {t("navbar.contact")}
        </Link>
        <Link to="/referenzen" className="nav-link" onClick={toggleNav}>
          {t("navbar.ref")}
        </Link>
        <Link to="/kooperationen" className="nav-link" onClick={toggleNav}>
          {t("navbar.coop")}
        </Link>
        <Link to="#" className="icon" onClick={toggleNav}>
          <i className="fa fa-bars"></i>
        </Link>
      </nav>
    </div>
  );
}

export default Nav;
